import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import Heading from '@bibliocommons/heading';
import { Link } from '@bibliocommons/base-links';
import { Badge, DarkRedBadge, DarkGreyBadge } from '@bibliocommons/base-badges';
import { selectEventSeriesEntities } from 'app/selectors/EntitiesSelector';
import { mapEventToEntity } from 'app/helpers/analytics/formatGA4Entity';
import { trackContentClick } from 'app/actions/AnalyticsGa4Actions';
import { eventShape } from 'app/shapes/events';

import './EventTitle.scss';

export default function EventTitle({ event, titleSize, analyticsEntity }) {
  const dispatch = useDispatch();
  const eventSeries = useSelector(selectEventSeriesEntities);
  const isCancelled = event.getIn(['definition', 'isCancelled']);
  const isFeatured = event.getIn(['definition', 'isFeatured']);
  const eventTitle = event.getIn(['definition', 'title']).trim();
  const size = titleSize || 'moderate';

  function isInProgress() {
    if (isCancelled) return false;
    const start = event.getIn(['definition', 'start']);
    const end = event.getIn(['definition', 'end']);
    const indexStart = event.get('indexStart');
    const indexEnd = event.get('indexEnd');
    const isAllDay = moment(start).isSame(end);
    if (isAllDay) {
      const today = moment().format('YYYY-MM-DD');
      return moment(today).isSame(start) && moment(today).isSame(end);
    }

    return moment.utc().isSameOrAfter(moment(indexStart)) && moment().isSameOrBefore(moment(indexEnd));
  }

  function handleEventTitle() {
    // GA4 content click event
    const search = {};
    const ui = {
      ui_container_type: 'item',
      ui_component_type: 'link-heading',
      ui_component_label: event.getIn(['definition', 'title']),
      ui_content_layout: 'medium',
      ui_content_page: analyticsEntity?.uiContentPage,
      ui_content_total_count: analyticsEntity?.uiContentTotalCount
    };
    if (analyticsEntity?.searchQuery) {
      search.search_query = analyticsEntity?.searchQuery;
      search.search_origin = 'user-query';
      search.search_type = 'keyword';
    }
    const eventEntity = mapEventToEntity({ event, series: eventSeries.get(event.get('seriesId')) });
    dispatch(trackContentClick({ event: eventEntity, ui, search }));
  }

  function renderBadge() {
    if (isCancelled) {
      return (
        <span className="event-badge">
          <DarkRedBadge size="small">
            <FormattedMessage id="cancelled" />
          </DarkRedBadge>
        </span>
      );
    }
    if (isInProgress()) {
      return (
        <span className="event-badge">
          <DarkGreyBadge size="small" inverted>
            <FormattedMessage id="in_progress" />
          </DarkGreyBadge>
        </span>
      );
    }
    return null;
  }

  function renderTitle() {
    if (isFeatured) {
      return (
        <>
          <span aria-hidden>{eventTitle}</span>
          <ScreenReaderMessage>
            <FormattedMessage id="sr_featured_event_title" values={{ eventTitle }} />
          </ScreenReaderMessage>
        </>
      );
    }
    return eventTitle;
  }

  return (
    <div className="cp-event-title">
      {isFeatured && (
        <span aria-hidden>
          <div className="featured-badge">
            <Badge>
              <FormattedMessage id="featured" />
            </Badge>
          </div>
        </span>
      )}
      <Heading tagName="h3" size={size} variant>
        <Link href={`/events/${event.get('id')}`} dataKey="event-link" handleClick={handleEventTitle}>
          {renderTitle()}
        </Link>
        {renderBadge()}
      </Heading>
    </div>
  );
}

EventTitle.propTypes = {
  event: eventShape.isRequired,
  titleSize: PropTypes.string,
  analyticsEntity: PropTypes.object
};
