import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import {
  EVENTS_FACET_FIELDS,
  MY_EVENTS_FACET_CATEGORIES,
  SUB_CATEGORIES_ORDER as EVENTS_SUB_CATEGORIES_ORDER
} from 'app/constants/EventsConstants';
import {
  ALL_FACET_FIELD_IDS,
  FACET_FILTER_UI_TYPE,
  FILTER_TYPE,
  GROUP_ID,
  UNGROUPED_GROUP_ID
} from 'app/constants/FacetsConstants';
import { SEARCH_FACET_FIELD_ID } from 'app/constants/BibConstants';

export const categoriesShape = PropTypes.arrayOf(PropTypes.oneOf(ALL_FACET_FIELD_IDS));

export const fieldIdShape = PropTypes.oneOf(Object.values(ALL_FACET_FIELD_IDS));

const groupIdShape = PropTypes.oneOf(
  Object.values(GROUP_ID[SEARCH_FACET_FIELD_ID.FORMAT]).concat(
    EVENTS_SUB_CATEGORIES_ORDER,
    Object.values(EVENTS_FACET_FIELDS),
    MY_EVENTS_FACET_CATEGORIES,
    UNGROUPED_GROUP_ID
  )
);

export const facetFilterShape = ImmutablePropTypes.mapContains({
  applied: PropTypes.bool.isRequired,
  count: PropTypes.number,
  isShownInCondensedView: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  localization: PropTypes.string,
  value: PropTypes.string.isRequired
});

export const activeFiltersShape = ImmutablePropTypes.mapOf(
  ImmutablePropTypes.listOf(facetFilterShape),
  PropTypes.oneOf(ALL_FACET_FIELD_IDS)
);

export const facetGroupShape = ImmutablePropTypes.mapContains({
  filters: ImmutablePropTypes.listOf(facetFilterShape).isRequired,
  groupId: groupIdShape.isRequired,
  hasShowMoreOverlay: PropTypes.bool.isRequired,
  labelId: PropTypes.string
});

export const facetFieldShape = ImmutablePropTypes.mapContains({
  collapsed: PropTypes.bool.isRequired,
  id: fieldIdShape.isRequired,
  filterUiType: PropTypes.oneOf(Object.values(FACET_FILTER_UI_TYPE)).isRequired,
  groups: ImmutablePropTypes.listOf(facetGroupShape),
  labelId: PropTypes.string.isRequired
});

export const facetFieldsShape = ImmutablePropTypes.listOf(facetFieldShape);

export const filterTypeShape = PropTypes.oneOf(Object.values(FILTER_TYPE));
