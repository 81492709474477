import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { Filter } from '@bibliocommons/base-icons';
import { SecondaryGhostButton } from '@bibliocommons/base-buttons';
import Heading from '@bibliocommons/heading';

import { FullScreenOverlayContainer } from 'app/components/shared/FullScreenOverlay';
import { activeFiltersShape, categoriesShape, facetFieldsShape, filterTypeShape } from 'app/shapes/facets';
import FacetList from './FacetList';
import MobileFacetsOverlay from './MobileFacetsOverlay';
import ShowMoreOverlay from './ShowMoreOverlay';

import './Facets.scss';

export default function Facets({
  categories,
  facetFields,
  activeFilters,
  totalResults,
  handleToggleFilter,
  startDate,
  endDate,
  handleCategoryToggle,
  filterType,
  singleBranch,
  activeFilterCategories
}) {
  const [mobileOverlayOpen, setMobileOverlayOpen] = useState(false);
  const [showMoreState, setShowMoreState] = useState(null);

  const overlayOpen = mobileOverlayOpen || !!showMoreState;
  const activeFiltersCount = activeFilters
    .toList()
    .flatten(true)
    .count();

  function openMobileOverlay() {
    setMobileOverlayOpen(true);
  }

  function closeMobileOverlay() {
    setMobileOverlayOpen(false);
  }

  function openShowMoreOverlay(fieldId, groupId) {
    setShowMoreState({ fieldId, groupId });
  }

  function closeShowMoreOverlay() {
    setShowMoreState(null);
  }

  function closeAllOverlays() {
    setMobileOverlayOpen(false);
    setShowMoreState(null);
  }

  function renderDesktopFacets() {
    return (
      <div className="desktop-facets">
        <ScreenReaderMessage>
          <Heading tagName="h2">
            <FormattedMessage id={`${filterType}_facets`} />
          </Heading>
        </ScreenReaderMessage>
        <FacetList
          categories={categories}
          facetFields={facetFields}
          activeFilters={activeFilters}
          handleToggleFilter={handleToggleFilter}
          handleShowMore={openShowMoreOverlay}
          handleCategoryToggle={handleCategoryToggle}
          singleBranch={singleBranch}
        />
      </div>
    );
  }

  function renderMobileFacets() {
    if (mobileOverlayOpen && !showMoreState) {
      return (
        <MobileFacetsOverlay
          categories={categories}
          facetFields={facetFields}
          activeFilters={activeFilters}
          totalResults={totalResults}
          handleClose={closeMobileOverlay}
          handleToggleFilter={handleToggleFilter}
          handleShowMore={openShowMoreOverlay}
          startDate={startDate}
          endDate={endDate}
          handleCategoryToggle={handleCategoryToggle}
          filterType={filterType}
          singleBranch={singleBranch}
          activeFilterCategories={activeFilterCategories}
        />
      );
    }

    return null;
  }

  function renderShowMoreOverlay() {
    if (showMoreState?.fieldId && showMoreState?.groupId) {
      const currentField = facetFields.find(field => field.get('id') === showMoreState.fieldId);
      const subCategory = currentField.get('groups').find(group => group.get('groupId') === showMoreState.groupId);
      let filters = subCategory.get('filters');
      if (showMoreState?.fieldId === 'locations') {
        const preferredLocations = currentField
          .get('groups')
          .find(group => group?.get('groupId') === 'preferredLocations')
          ?.get('filters');
        filters = preferredLocations ? filters.concat(preferredLocations).sort() : filters;
      }
      const handleAllFilters = mobileOverlayOpen ? closeShowMoreOverlay : undefined;

      return (
        <ShowMoreOverlay
          fieldId={showMoreState.fieldId}
          filters={filters}
          handleToggleFilter={handleToggleFilter}
          handleAllFilters={handleAllFilters}
          subCategory={subCategory}
        />
      );
    }

    return null;
  }

  return (
    <div className="cp-facets">
      {renderDesktopFacets()}
      <SecondaryGhostButton
        className="mobile-facet-button"
        dataKey="mobile-facet-button"
        handleClick={openMobileOverlay}
        renderLeadingIcon={() => <Filter />}
      >
        {activeFiltersCount ? (
          <>
            <span aria-hidden>
              <FormattedMessage id="filter_count" values={{ count: activeFiltersCount }} />
            </span>
            <ScreenReaderMessage>
              <FormattedMessage id="sr_filter_count" values={{ count: activeFiltersCount }} />
            </ScreenReaderMessage>
          </>
        ) : (
          <>
            <span aria-hidden>
              <FormattedMessage id="filter" />
            </span>
            <ScreenReaderMessage>
              <FormattedMessage id="sr_filter_opens_overlay" />
            </ScreenReaderMessage>
          </>
        )}
      </SecondaryGhostButton>
      <FullScreenOverlayContainer
        open={overlayOpen}
        handleClose={closeAllOverlays}
        overlayKey="facets-overlay"
        dataKey="facets-overlay"
      >
        {renderMobileFacets()}
        {renderShowMoreOverlay()}
      </FullScreenOverlayContainer>
    </div>
  );
}

Facets.propTypes = {
  activeFilters: activeFiltersShape.isRequired,
  categories: categoriesShape.isRequired,
  facetFields: facetFieldsShape.isRequired,
  filterType: filterTypeShape.isRequired,
  totalResults: PropTypes.number,
  handleToggleFilter: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  handleCategoryToggle: PropTypes.func,
  singleBranch: PropTypes.bool,
  activeFilterCategories: PropTypes.array
};
