import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ChevronDown, ChevronRight } from '@bibliocommons/base-icons';
import { Badge } from '@bibliocommons/base-badges';
import Heading from '@bibliocommons/heading';

import './CategoryHeading.scss';

export default function CategoryHeading({ collapsed, handleToggle, numAppliedFilters, title }) {
  function renderIcon() {
    return collapsed ? <ChevronRight className="chevron-icon" /> : <ChevronDown className="chevron-icon" />;
  }

  function renderFilterCount() {
    if (!numAppliedFilters) {
      return null;
    }

    return (
      <Badge size="small" rounded>
        {numAppliedFilters}
      </Badge>
    );
  }

  return (
    <div className="cp-category-heading">
      <button type="button" onClick={handleToggle} aria-expanded={!collapsed}>
        {renderIcon()}

        <Heading tagName="fieldset" size="modest" className="category-name">
          <legend className="category-heading">
            <FormattedMessage id={title} />
          </legend>
        </Heading>
        {renderFilterCount()}
      </button>
    </div>
  );
}

CategoryHeading.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  numAppliedFilters: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};
