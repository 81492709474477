import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';
import { FormattedHTMLMessage } from '@bibliocommons/react-intl';
import { Checkmark, Spinner } from '@bibliocommons/base-icons';
import { TransactionalSolidButton, SecondarySolidButton } from '@bibliocommons/base-buttons';
import { ModalHeader, ModalContent, ModalFooter } from '@bibliocommons/modal';
import { EVENTS_PDF_URL, BROCHURE_LIMIT } from 'app/constants/EventsConstants';
import ModalActions from 'app/actions/ModalActions';
import { selectAppConfig } from 'app/selectors/AppSelector';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';

import './EventsPdfDownloadModal.scss';

export default function EventsPdfDownloadModal({ pagination, modalKey }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const appConfig = useSelector(selectAppConfig);
  const currentLibrary = useSelector(selectCurrentLibrary);
  const [isLoading, setIsLoading] = useState(pagination.get('count') < BROCHURE_LIMIT);
  const [isComplete, setIsComplete] = useState(false);
  const fileName = `Events at ${currentLibrary.get('longName').slice(0, 45)}.pdf`;
  const downloadUrl = `${appConfig.get('baseURL')}${EVENTS_PDF_URL}${location?.search ? location?.search : ''}`;
  let retryUrl;

  function handleClose() {
    dispatch(ModalActions.closeModal(modalKey));
  }

  async function handleFileDownload() {
    setIsLoading(true);

    try {
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        return;
      }
      setIsLoading(false);
      setIsComplete(true);

      const blob = await response.blob();

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = fileName;

      const elem = document.getElementById('download-link');
      elem.appendChild(link);
      link.click();

      retryUrl = url;
      const retryElem = document.getElementById('retry-download');
      retryElem.href = url;
      retryElem.download = fileName;
    } catch (error) {
      /* eslint no-console: ["error", { allow: ["error"] }] */
      console.error(error);
    }
  }

  useEffect(() => {
    if (pagination.get('count') < BROCHURE_LIMIT) {
      handleFileDownload();
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cp-events-pdf-modal">
      <ModalHeader title={<FormattedMessage defaultMessage="Create PDF" id="create_pdf" />} />
      {isLoading && (
        <ModalContent>
          <div className="creating-pdf">
            <Spinner primary />
            <span>
              <FormattedMessage defaultMessage="Creating PDF..." id="pdf.events.creating" />
            </span>
          </div>
        </ModalContent>
      )}
      {isComplete && (
        <>
          <ModalContent>
            <div className="pdf-ready">
              <div className="pdf-ready-text">
                <span id="download-link"></span>
                <Checkmark />
                <FormattedMessage defaultMessage="PDF ready for download" id="pdf.events.ready" />
              </div>
              <div className="pdf-download-link">
                <FormattedHTMLMessage
                  defaultMessage="<p>If the PDF download doesn't begin in a few seconds <a id='retry-download' href='{retryUrl}'>click here to manually start the download.</a></p>"
                  id="pdf.events.download.text-1"
                  values={{ retryUrl }}
                />
              </div>
            </div>
          </ModalContent>
          <ModalFooter
            className="download-complete"
            primaryActionButtonText={<FormattedMessage defaultMessage="Done" id="done" />}
            primaryActionButtonTag={SecondarySolidButton}
            handlePrimaryAction={handleClose}
            dataKey="pdf-download-done"
          />
        </>
      )}
      {pagination.get('count') > BROCHURE_LIMIT && !isLoading && !isComplete && (
        <>
          <ModalContent>
            <FormattedHTMLMessage
              defaultMessage="A PDF can only be created with 200 or fewer events."
              id="pdf.events.description"
            />
          </ModalContent>
          <ModalFooter
            primaryActionButtonText={
              <FormattedMessage defaultMessage="Create with first 200 events" id="pdf.events.create_button" />
            }
            primaryActionButtonTag={TransactionalSolidButton}
            secondaryActionButtonText={<FormattedMessage defaultMessage="Cancel" id="button_cancel" />}
            handlePrimaryAction={handleFileDownload}
            dataKey="events-pdf-modal"
          />
        </>
      )}
    </div>
  );
}

EventsPdfDownloadModal.propTypes = {
  pagination: ImmutablePropTypes.map,
  modalKey: PropTypes.string.isRequired
};
