import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  FullScreenOverlayBody,
  FullScreenOverlayHeader,
  FullScreenOverlayContent,
  FullScreenOverlayFooter
} from '@bibliocommons/base-full-screen-overlay';
import { useRouter } from '@bibliocommons/utils-routing';
import { SecondaryGhostButton, SecondarySolidButton } from '@bibliocommons/base-buttons';

import { activeFiltersShape, categoriesShape, facetFieldsShape, filterTypeShape } from 'app/shapes/facets';
import FacetList from '../FacetList';
import ActiveFilters from '../ActiveFilters';

import './MobileFacetsOverlay.scss';

export default function MobileFacetsOverlay({
  handleToggleFilter,
  categories,
  facetFields,
  activeFilters,
  totalResults,
  handleShowMore,
  handleClose,
  startDate,
  endDate,
  handleCategoryToggle,
  filterType,
  singleBranch,
  activeFilterCategories
}) {
  const router = useRouter();

  function renderClearButton() {
    return (
      <SecondaryGhostButton
        className="mobile-clear-button"
        dataKey="mobile-clear-button"
        handleClick={() => router.replace({ query: null })}
      >
        <FormattedMessage id="clear_filters" />
      </SecondaryGhostButton>
    );
  }

  function renderDoneButton() {
    return (
      <SecondarySolidButton dataKey="mobile-done-button" handleClick={handleClose}>
        <FormattedMessage id="done" />
      </SecondarySolidButton>
    );
  }

  return (
    <div className="cp-mobile-facets-overlay">
      <FullScreenOverlayBody>
        <FullScreenOverlayHeader
          title={<FormattedMessage id={`${filterType}_facets`} />}
          subtitle={<FormattedMessage id="total_results" values={{ total: totalResults }} />}
        />
        <FullScreenOverlayContent className="mobile-facet-overlay" size="small">
          {(activeFilters.size > 0 || !!startDate || !!endDate) && (
            <ActiveFilters
              handleToggleFilter={handleToggleFilter}
              activeFilters={activeFilters}
              categories={activeFilterCategories || categories}
              startDate={startDate}
              endDate={endDate}
              headingTag="h3"
              filterType={filterType}
            />
          )}
          <FacetList
            categories={categories}
            facetFields={facetFields}
            activeFilters={activeFilters}
            handleToggleFilter={handleToggleFilter}
            handleShowMore={handleShowMore}
            handleCategoryToggle={handleCategoryToggle}
            singleBranch={singleBranch}
          />
        </FullScreenOverlayContent>
        <FullScreenOverlayFooter renderSecondaryAction={renderClearButton} renderPrimaryAction={renderDoneButton} />
      </FullScreenOverlayBody>
    </div>
  );
}

MobileFacetsOverlay.propTypes = {
  activeFilterCategories: PropTypes.array,
  activeFilters: activeFiltersShape.isRequired,
  categories: categoriesShape.isRequired,
  endDate: PropTypes.string,
  facetFields: facetFieldsShape.isRequired,
  filterType: filterTypeShape.isRequired,
  handleCategoryToggle: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  handleShowMore: PropTypes.func.isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  singleBranch: PropTypes.bool,
  startDate: PropTypes.string,
  totalResults: PropTypes.number.isRequired
};
