import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import Divider from '@bibliocommons/divider';

import { activeFiltersShape, categoriesShape, facetFieldsShape } from 'app/shapes/facets';
import FacetCategory from './FacetCategory';
import './FacetList.scss';

export default function FacetList({
  categories,
  facetFields,
  handleToggleFilter,
  handleShowMore,
  activeFilters,
  handleCategoryToggle,
  singleBranch
}) {
  const indexedFields = facetFields.reduce((result, field) => result.set(field.get('id'), field), Immutable.Map());

  function renderCategory(category, index) {
    const field = indexedFields.get(category);
    if (!field || (field.get('groups') && field.get('groups').isEmpty())) {
      return null;
    }

    return (
      <div key={field.get('id')}>
        {index > 0 && <Divider className="category-divider" />}
        <FacetCategory
          field={field}
          filterUiType={field.get('filterUiType')}
          activeFilters={activeFilters}
          handleToggleFilter={handleToggleFilter}
          handleShowMore={handleShowMore}
          handleCategoryToggle={handleCategoryToggle}
          singleBranch={singleBranch}
        />
      </div>
    );
  }
  return <div className="cp-facet-list">{categories.map(renderCategory)}</div>;
}

FacetList.propTypes = {
  activeFilters: activeFiltersShape.isRequired,
  categories: categoriesShape.isRequired,
  facetFields: facetFieldsShape.isRequired,
  handleCategoryToggle: PropTypes.func,
  handleShowMore: PropTypes.func.isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  singleBranch: PropTypes.bool
};
