import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { PROVIDERS } from 'app/constants/EventsConstants';

export default ImmutablePropTypes.mapContains({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  seriesId: PropTypes.string.isRequired,
  definition: ImmutablePropTypes.mapContains({
    isFeatured: PropTypes.boolean,
    isCancelled: PropTypes.boolean,
    contact: ImmutablePropTypes.mapContains({
      phone: ImmutablePropTypes.mapContains({
        contactType: PropTypes.string,
        globalValue: PropTypes.string,
        value: PropTypes.string
      }),
      email: ImmutablePropTypes.mapContains({
        contactType: PropTypes.string,
        globalValue: PropTypes.string,
        value: PropTypes.string
      })
    }),
    registrationInfo: ImmutablePropTypes.mapContains({
      provider: PropTypes.oneOf(Object.values(PROVIDERS)),
      registrationStart: ImmutablePropTypes.mapContains({
        unit: PropTypes.string,
        date: PropTypes.string,
        time: PropTypes.string,
        ordinal: PropTypes.nuber,
        windowType: PropTypes.string
      }),
      registrationEnd: ImmutablePropTypes.mapContains({
        unit: PropTypes.string,
        date: PropTypes.string,
        time: PropTypes.string,
        ordinal: PropTypes.nuber,
        windowType: PropTypes.string
      }),
      cap: PropTypes.number,
      maxSeats: PropTypes.number,
      isFull: PropTypes.boolean,
      instructions: PropTypes.string,
      onlineInstructions: PropTypes.string,
      instructionsToAttend: PropTypes.string,
      waitlistEnabled: PropTypes.boolean
    }),
    typeIds: ImmutablePropTypes.listOf(PropTypes.string),
    programId: PropTypes.string,
    languageIds: ImmutablePropTypes.listOf(PropTypes.string),
    audienceIds: ImmutablePropTypes.listOf(PropTypes.string),
    nonBranchLocationId: PropTypes.string,
    branchLocationId: PropTypes.string,
    featuredImageId: PropTypes.string,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired
  }).isRequired,
  indexStart: PropTypes.string.isRequired,
  indexEnd: PropTypes.string.isRequired,
  isRecurring: PropTypes.boolean,
  numberRegistered: PropTypes.number,
  numberWaitlistRegistered: PropTypes.number,
  isFull: PropTypes.boolean,
  registrationClosed: PropTypes.boolean
});
