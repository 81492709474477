import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { TextButton } from '@bibliocommons/base-buttons';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';

import { FACET_FILTER_UI_TYPE } from 'app/constants/FacetsConstants';
import { facetGroupShape, fieldIdShape } from 'app/shapes/facets';
import Filter from '../../Filter';

import './FacetSubCategory.scss';

export default function FacetSubCategory({
  fieldId,
  filterUiType,
  subCategory,
  handleToggleFilter,
  handleShowMore,
  singleBranch
}) {
  function renderFilters(filter) {
    if (!filter.get('isShownInCondensedView')) {
      return null;
    }

    return (
      <Filter
        fieldId={fieldId}
        filter={filter}
        filterUiType={filterUiType}
        handleToggleFilter={handleToggleFilter}
        key={`filter-${fieldId}-${filter.get('value')}`}
        subCategory={subCategory}
      />
    );
  }

  function renderShowMoreButton() {
    return (
      <TextButton
        className="show-more"
        dataKey={`show-more-${fieldId}`}
        handleClick={() => handleShowMore(fieldId, subCategory.get('groupId'))}
        leftAligned
      >
        <span aria-hidden>
          <FormattedMessage id="button_show_more" />
        </span>
        <ScreenReaderMessage>
          <FormattedMessage id="sr_show_more_opens_overlay" />
        </ScreenReaderMessage>
      </TextButton>
    );
  }

  return (
    <div className="cp-facet-subcategory" key={subCategory.get('groupId')}>
      {subCategory.get('labelId') && !singleBranch && (
        <div className="subcategory-title">
          <FormattedMessage id={subCategory.get('labelId')} />
        </div>
      )}
      <div className="field-group">
        <ul className="filter-list">{subCategory.get('filters').map(renderFilters)}</ul>
      </div>
      {subCategory.get('hasShowMoreOverlay') && renderShowMoreButton()}
    </div>
  );
}

FacetSubCategory.propTypes = {
  fieldId: fieldIdShape.isRequired,
  subCategory: facetGroupShape.isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  handleShowMore: PropTypes.func.isRequired,
  singleBranch: PropTypes.bool,
  filterUiType: PropTypes.oneOf(Object.values(FACET_FILTER_UI_TYPE)).isRequired
};
