import Immutable from 'immutable';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FACET_FILTER_UI_TYPE } from 'app/constants/FacetsConstants';
import { activeFiltersShape, facetFieldShape } from 'app/shapes/facets';
import CategoryHeading from '../CategoryHeading';
import FacetSubCategory from '../FacetSubCategory';
import DateField from '../DateField';
import SelectedPillFilters from '../SelectedPillFilters';

import './FacetCategory.scss';

export default function FacetCategory({
  field,
  filterUiType,
  handleToggleFilter,
  handleShowMore,
  activeFilters,
  handleCategoryToggle,
  singleBranch
}) {
  const [collapsed, setCollapsed] = useState(field.get('collapsed'));

  function handleToggle() {
    if (handleCategoryToggle) handleCategoryToggle(collapsed, field);
    setCollapsed(!collapsed);
  }

  function renderSubCategory(subCategory) {
    return (
      <FacetSubCategory
        key={`facet-category-${subCategory.get('groupId')}`}
        fieldId={field.get('id')}
        subCategory={subCategory}
        handleToggleFilter={handleToggleFilter}
        handleShowMore={handleShowMore}
        singleBranch={singleBranch}
        filterUiType={filterUiType}
      />
    );
  }

  function renderContents() {
    switch (filterUiType) {
      case FACET_FILTER_UI_TYPE.DATE_RANGE: {
        return <DateField handleToggleFilter={handleToggleFilter} />;
      }
      case FACET_FILTER_UI_TYPE.SELECTED_PILLS: {
        const toggleFilter = filter => {
          handleToggleFilter(field.get('id'), Immutable.fromJS(filter));
        };
        return <SelectedPillFilters facetField={field} toggleFilter={toggleFilter} />;
      }
      default:
        return field.get('groups').map(renderSubCategory);
    }
  }

  const numAppliedFilters = activeFilters.get(field.get('id'))?.size ?? 0;
  const hasAppliedFiltersCount = filterUiType !== FACET_FILTER_UI_TYPE.RADIO_BUTTONS;

  return (
    <fieldset className="cp-facet-category" key={field.get('id')}>
      <CategoryHeading
        collapsed={collapsed}
        handleToggle={handleToggle}
        numAppliedFilters={hasAppliedFiltersCount ? numAppliedFilters : 0}
        title={field.get('labelId')}
      />

      {!collapsed && <div className="facet-category-contents">{renderContents()}</div>}
    </fieldset>
  );
}

FacetCategory.propTypes = {
  activeFilters: activeFiltersShape.isRequired,
  field: facetFieldShape.isRequired,
  filterUiType: PropTypes.oneOf(Object.values(FACET_FILTER_UI_TYPE)).isRequired,
  handleCategoryToggle: PropTypes.func,
  handleShowMore: PropTypes.func.isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  singleBranch: PropTypes.bool
};
