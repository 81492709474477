import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SecondaryLink } from '@bibliocommons/base-links';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { selectEventSeriesEntities } from 'app/selectors/EntitiesSelector';
import { mapEventToEntity } from 'app/helpers/analytics/formatGA4Entity';
import { trackContentClick } from 'app/actions/AnalyticsGa4Actions';
import { getRegistrationStatusMessage } from 'app/helpers/events/utils';
import { eventShape } from 'app/shapes/events';
import { UI_CONTAINER_TYPE, UI_COMPONENT_TYPE } from 'app/constants/AnalyticsGa4Constants';

import './EventRegistrationMessage.scss';

export default function EventRegistrationMessage({ event, analyticsEntity }) {
  const dispatch = useDispatch();
  const eventSeries = useSelector(selectEventSeriesEntities);
  const registrationInfo = event.getIn(['definition', 'registrationInfo']);
  const registrationClosed = event.get('registrationClosed');
  const waitlistEnabled = registrationInfo.get('waitlistEnabled');
  const isWaitlistActive = registrationClosed && waitlistEnabled;
  const eventTitle = event.getIn(['definition', 'title']);
  const eventDate = moment(event.getIn(['definition', 'start'])).format('MM-DD-YYYY');

  // GA4 content click event
  function handleRegisterLink(waitlist = false) {
    const search = {};
    const ui = {
      ui_container_type: UI_CONTAINER_TYPE.ITEM,
      ui_component_type: UI_COMPONENT_TYPE.LINK_TEXT,
      ui_component_label: 'Register for event',
      ui_content_layout: 'medium',
      ui_content_page: analyticsEntity?.uiContentPage,
      ui_content_total_count: analyticsEntity?.uiContentTotalCount
    };
    if (waitlist) {
      ui.ui_component_label = 'Join waitlist';
    }
    const eventEntity = mapEventToEntity({ event, series: eventSeries.get(event.get('seriesId')) });
    if (analyticsEntity?.searchQuery) {
      search.search_query = analyticsEntity?.searchQuery;
      search.search_origin = 'user-query';
      search.search_type = 'keyword';
    }
    dispatch(trackContentClick({ event: eventEntity, ui, search }));
  }

  function renderRegisterLink() {
    return (
      <SecondaryLink
        className="register-link"
        href={`/events/${event.get('id')}`}
        dataKey="registration-link"
        handleClick={() => handleRegisterLink(false)}
      >
        {isWaitlistActive ? <FormattedMessage id="join_waitlist" /> : <FormattedMessage id="register_for_event" />}
      </SecondaryLink>
    );
  }

  const message = getRegistrationStatusMessage(event);
  return message ? (
    <div className="cp-registration-message">
      {message?.sr_id && (
        <ScreenReaderMessage>
          <FormattedMessage id={message?.sr_id} values={{ eventTitle, eventDate }} />
        </ScreenReaderMessage>
      )}
      <span className={`registration-${message?.state}`}>
        <FormattedMessage defaultMessage={message?.defaultMessage} id={message?.id} values={message?.values} />
      </span>
      {message?.registerLink && renderRegisterLink()}
    </div>
  ) : null;
}

EventRegistrationMessage.propTypes = {
  event: eventShape.isRequired,
  analyticsEntity: PropTypes.object
};
