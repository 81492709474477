import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import Checkbox from '@bibliocommons/base-checkbox';
import RadioButton from '@bibliocommons/base-radio-button';

import { FACET_FILTER_UI_TYPE } from 'app/constants/FacetsConstants';
import { facetFilterShape, facetGroupShape, fieldIdShape } from 'app/shapes/facets';

import './Filter.scss';

export default function Filter({ fieldId, filter, filterUiType, subCategory, handleToggleFilter }) {
  const intl = useIntl();
  const hasFilterCount = Number.isInteger(filter.get('count'));
  const filterLabel = `${filter.get('label')}${hasFilterCount ? ` (${intl.formatNumber(filter.get('count'))})` : ''}`;
  const radioButtonLabel = filter.get('localization') ? (
    <FormattedMessage id={filter.get('localization')} />
  ) : (
    filter.get('label')
  );

  function renderFilter() {
    switch (filterUiType) {
      case FACET_FILTER_UI_TYPE.RADIO_BUTTONS: {
        return (
          <RadioButton
            className="filter-radiobutton"
            id={filter.get('value')}
            label={radioButtonLabel}
            checked={filter.get('applied')}
            name="event-date"
            handleChange={() => handleToggleFilter(filter)}
          />
        );
      }
      case FACET_FILTER_UI_TYPE.CHECKBOXES: {
        return (
          <Checkbox
            className="filter-checkbox"
            id={filter.get('value')}
            label={filterLabel}
            checked={filter.get('applied')}
            handleChange={() => handleToggleFilter(fieldId, filter, subCategory)}
          />
        );
      }
      default:
        throw new Error(`Unknown filterUiType ${filterUiType}`);
    }
  }

  return (
    <li className="cp-facet-filter" key={filter.get('value')}>
      {renderFilter()}
    </li>
  );
}

Filter.propTypes = {
  fieldId: fieldIdShape.isRequired,
  filter: facetFilterShape.isRequired,
  filterUiType: PropTypes.oneOf(Object.values(FACET_FILTER_UI_TYPE)).isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  subCategory: facetGroupShape.isRequired
};
