import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { TextButton } from '@bibliocommons/base-buttons';
import { Pill } from '@bibliocommons/pill';

import { facetFieldShape } from 'app/shapes/facets';

import './SelectedPillFilters.scss';

const SelectedPillFilters = ({ facetField, toggleFilter }) => {
  const intl = useIntl();

  const groups = facetField.get('groups').toJS();
  const allAppliedFilters = groups.reduce((acc, group) => {
    return acc.concat(group.filters.filter(filter => filter.applied));
  }, []);

  const hasGroupHeadings = groups.length > 1;

  return (
    <div className="cp-selected-pill-filters">
      {Boolean(allAppliedFilters.length) && (
        <div>
          <ul className="list">
            {allAppliedFilters.map(filter => {
              const { label, value } = filter;

              return (
                <li className="pill-item" key={value}>
                  <Pill key={value} handleDismiss={() => toggleFilter(filter)}>
                    {label}
                  </Pill>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <ul className="list">
        {groups.map(group => {
          const { filters, groupId, labelId } = group;

          const unappliedFilters = filters.filter(filter => !filter.applied);
          if (!unappliedFilters.length) {
            return null;
          }

          return (
            <li key={groupId}>
              {hasGroupHeadings && <h3 className="group-heading">{intl.formatMessage({ id: labelId })}</h3>}
              <ul className="list">
                {unappliedFilters.map(filter => {
                  const { count, label, value } = filter;

                  const hasCount = Number.isInteger(count);
                  const formattedLabel = `${label}${hasCount ? ` (${intl.formatNumber(count)})` : ''}`;

                  return (
                    <li key={value}>
                      <TextButton
                        className="unapplied-filter"
                        dataKey={value}
                        handleClick={() => toggleFilter(filter)}
                        leftAligned
                        type="button"
                      >
                        {formattedLabel}
                      </TextButton>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

SelectedPillFilters.propTypes = {
  facetField: facetFieldShape.isRequired,
  toggleFilter: PropTypes.func.isRequired
};

export default SelectedPillFilters;
