import moment from 'moment';
import { stripTags, truncate } from '@bibliocommons/utils-html';
import { PROVIDERS, REGISTRATION_WINDOW_STATUSES } from 'app/constants/EventsConstants';

const REGISTRATION = {
  REQUIRED: 'Registration required',
  CLOSED: 'Registration closed'
};

function formatTitle(title) {
  // strip out special characters (e.g. #, $) replace & with 'and'
  return title.replace(/[#$]/g, '').replace(/[&]/g, 'and');
}

function formatDescription(description, length) {
  let plainDescription = stripTags(description, '<p>').replace(/<[^>]*>/g, '');
  if (length) {
    plainDescription = truncate(plainDescription, length);
  }
  return plainDescription.replace(/[\r\n]+/gm, '\\n').replace(/[#$&]/gm, '');
}

function formatDate(date, format) {
  return moment(date)
    .utc()
    .format(format);
}

function parseDateTime(dateTimeStr) {
  const [dateStr, timeStr] = dateTimeStr.split('T');
  return {
    date: dateStr ? moment(dateTimeStr).format('LL') : null,
    time: timeStr ? moment(dateTimeStr).format('LT') : null
  };
}

/* eslint-disable no-useless-concat */
function formatAddress(location) {
  const address =
    `${location?.get('number')} ` ||
    '' + `${location?.get('street')}, ` ||
    '' + `${location?.get('city')} ` ||
    '' + `${location?.get('state')} ` ||
    '' + `${location?.get('zip')}` ||
    '';
  return address ? location?.get('name') : `${location?.get('name')}, ${address}`;
}

function isAllDayEvent(start, end) {
  const { date: startDate, time: startTime } = parseDateTime(start);
  const { date: endDate, time: endTime } = parseDateTime(end);
  return startDate === endDate && !startTime && !endTime;
}

function convertHtmlToText(description, length = 120) {
  let plainDescription = stripTags(description, '<p>').replace(/<[^>]*>/g, '');
  if (length) {
    plainDescription = truncate(plainDescription, length);
  }
  return plainDescription
    .replace(/(&nbsp;)+/gm, ' ')
    .replace(/[\r\n]+/gm, ' ')
    .replace(/[#$&]/gm, ' ');
}

function groupEventsByDate(events) {
  const eventGroups = events.reduce((groups, event) => {
    const date = event.getIn(['definition', 'start'])?.split('T')[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(event);
    return groups;
  }, {});

  const groupedEvents = Object.keys(eventGroups)
    .sort()
    .map(date => {
      return {
        date,
        events: eventGroups[date]
      };
    });
  return groupedEvents;
}

/* return active filters with label from Query - used in PDF file */
function getActiveFilters(query, brochureData, locale) {
  const activeFilters = [];
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC'
  };

  query.forEach((categoryValues, category) => {
    const filters = categoryValues.split(',');
    filters.forEach(value => {
      let filterLabel;

      switch (category) {
        case 'locations': {
          if (value === 'BC_VIRTUAL') {
            filterLabel = 'Online events';
          } else {
            filterLabel = brochureData?.getIn([category, value, 'name']);
          }
          break;
        }

        case 'startDate': {
          filterLabel = `From: ${new Date(value).toLocaleDateString(locale, dateOptions)}`;
          break;
        }
        case 'endDate': {
          filterLabel = `To: ${new Date(value).toLocaleDateString(locale, dateOptions)}`;
          break;
        }
        default: {
          filterLabel = brochureData?.getIn([category, value, 'name']);
          break;
        }
      }

      activeFilters.push({
        label: filterLabel,
        value
      });
    });
  });

  return activeFilters;
}

/* Registration status message */
function getRegistrationStatusMessage(event) {
  const isCancelled = event.getIn(['definition', 'isCancelled']);
  const isPast = moment(event.get('indexEnd')).isBefore(moment());
  const registrationInfo = event.getIn(['definition', 'registrationInfo']);
  const provider = registrationInfo.get('provider');
  const isFull = event?.get('isFull');

  if (isPast || isCancelled) {
    return null;
  }

  if (provider === PROVIDERS.EXTERNAL) {
    if (isFull) {
      return {
        defaultMessage: REGISTRATION.REQUIRED,
        id: 'registration_full',
        state: 'disabled'
      };
    }
    return {
      defaultMessage: REGISTRATION.REQUIRED,
      id: 'registration_required',
      sr_id: 'sr_register_for_event',
      state: 'active',
      registerLink: true
    };
  }

  if (provider === PROVIDERS.BIBLIO_EVENTS) {
    const registrationWindow = event?.get('registrationWindow');
    const registrationStatus = event?.getIn(['registrationWindow', 'status']);
    const registrationClosed = event.get('registrationClosed');
    const waitlistEnabled = registrationInfo.get('waitlistEnabled');
    const isWaitlistActive = registrationClosed && waitlistEnabled;
    const registrationEnd = moment(registrationWindow?.get('windowEnd'));
    const spotsAvailable = registrationInfo?.get('cap') - event.get('numberRegistered');
    const eventStart = moment(event.getIn(['definition', 'start']));

    const windowStart = moment(registrationWindow?.get('windowStart'));
    const windowEnd = moment(registrationWindow?.get('windowEnd'));
    const windowStartDate = windowStart?.format('MMMM D');
    const windowStartTime = windowStart?.format('h:mma');
    const windowEndDate = windowEnd?.format('MMMM D');
    const windowEndTime = windowEnd?.format('h:mma');

    switch (registrationStatus) {
      case REGISTRATION_WINDOW_STATUSES.ENDED: {
        return {
          defaultMessage: REGISTRATION.CLOSED,
          id: 'registration_closed',
          state: 'disabled'
        };
      }

      case REGISTRATION_WINDOW_STATUSES.UPCOMING: {
        if (isWaitlistActive) {
          return {
            defaultMessage: REGISTRATION.REQUIRED,
            id: 'registration_required',
            sr_id: 'sr_register_for_event',
            state: 'disabled',
            registerLink: true
          };
        }

        if (registrationWindow.get('windowStart')) {
          return {
            defaultMessage: `Registration opens ${windowStartDate} at ${windowStartTime}`,
            id: 'registration_window_opens_at',
            values: { date: windowStartDate, time: windowStartTime },
            state: 'inactive'
          };
        }
        return null;
      }

      case REGISTRATION_WINDOW_STATUSES.ACTIVE: {
        if (isFull && !isWaitlistActive) {
          return {
            defaultMessage: REGISTRATION.REQUIRED,
            id: 'registration_full',
            sr_id: 'sr_register_for_event',
            state: 'disabled'
          };
        }

        if (isFull && isWaitlistActive) {
          return {
            defaultMessage: REGISTRATION.REQUIRED,
            id: 'registration_waitlisted',
            sr_id: 'sr_join_waitlist',
            state: 'waitlist',
            registerLink: true
          };
        }

        if (!isFull && !isWaitlistActive && registrationEnd?.isBefore(eventStart)) {
          return {
            defaultMessage: `Registration closes ${windowEndDate} at ${windowEndTime}`,
            id: 'registration_window_closes_at',
            values: { date: windowEndDate, time: windowEndTime, numSpots: spotsAvailable },
            sr_id: 'sr_register_for_event',
            state: 'active',
            registerLink: true
          };
        }

        return {
          defaultMessage: REGISTRATION.REQUIRED,
          id: 'registration_required_online',
          sr_id: 'sr_register_for_event',
          values: { numSpots: spotsAvailable },
          state: 'active',
          registerLink: true
        };
      }

      default:
        return null;
    }
  }
  return null;
}

function parseEventId(card) {
  if (card.getIn(['event', 'eventId'])) {
    return card.getIn(['event', 'eventId']);
  }
  return card.get('url').split('/')[4];
}

export {
  convertHtmlToText,
  formatTitle,
  formatDescription,
  formatDate,
  formatAddress,
  getActiveFilters,
  getRegistrationStatusMessage,
  groupEventsByDate,
  isAllDayEvent,
  parseEventId
};
